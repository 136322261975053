@import '../../style/custonVariable';
.MainContainer{
    position: relative;
    // background: $panel-bg-color;
    height: 100%;
    margin-top: $tiles-gap-height;
    @media (max-width: 600px) { 
        margin-top: 3px;
      }
    
    .summary{
        padding: 20px;
        position: relative;
        // margin-bottom: 50px;
        .tabContent{
            flex-wrap: wrap;
            width: 100%;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            padding: 25px;
            background: #fff;
            // border-radius: 1px;  
            border-radius: 2%;  
         
        }  
    }
} 

.subcontainercard{
    margin: 20px !important; 
    padding: 25px;
    width: "100%";
    height: '100%';
    // margin:10%;
    min-height: 300px;
  
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
background: #fff;
margin-top: 50;
    h4{
text-align: center;
    }
    h2{
        text-align: center;
    }
    .requirementssec{
        display: "flex";
        flex-direction: "row";
        justify-content: "space-between";
    
     } 
  }
  .subcontainercarddark{
    margin: 20px;
    padding: 20px;
    width: "100%";
    height: '100%';
    // margin:10%;
    min-height: 300px;
  
    grid-template-rows: 20px 50px 1fr 50px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
background: #0F2036 ;
    h4{
text-align: center;
    }
    h2{
        text-align: center;
    }
    .requirementssec{
        display: "flex";
        flex-direction: "row";
        justify-content: "space-between";
    
     } 
  }
  .circular{
    display: flex;
    justify-content: center;
    margin: 20;
    align-items: center;
   
}
.progress{
width:100%;
align-self: center;


}
.textalign{
    text-align: center;
}
.progressbarcontainer{
    display: flex;
    justify-content: space-between;
    // flex-direction: column;
    margin: 10;
padding:10

}
  
  .subcontainercard:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
   
      

}
.subcontainercard {
    background: "#D8D9DA";
    // background: rgba( 255, 255, 255, 0.25 );
// box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
// backdrop-filter: blur( 4px );
// -webkit-backdrop-filter: blur( 4px );
// border-radius: 10px;
// border: 1px solid rgba( 255, 255, 255, 0.18 );
  }
   
 


  .subcontainercarddark:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
    // .card {
     
    //   }
      

}





/*********************************** DARK MODE ************************************/

.MainContainer{
    position: relative;
    // background: $panel-bg-color;
    height: 100%;
    margin-top: $tiles-gap-height;
    @media (max-width: 600px) { 
        margin-top: 3px;
      }
   
  
    .summary{
        padding: 20px;
        position: relative;
        .dark_mode_tabContent{
            flex-wrap: wrap;
            width: 100%;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
            padding: 25px;
            background: #0F2036 !important;
            // border-radius: 1px;  
            border-radius: 2%;  
            .dataTable{
                padding-top: 15px;
                .tables{
                    padding-top: 10px;
                    font-family: system-ui;
                   
                }
               
            }}}}
          
           

/******************************** END OF DARK MODE *******************************/